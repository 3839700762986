import api from "@/axios";

export default {
  findAll() {
    return api().get("about-us");
  },
  findOne(id) {
    return api().get(`admin/about-us/${id}`);
  },
  create(data) {
    return api().post("about-us", data);
  },
  update(id, data) {
    return api().patch(`admin/about-us/${id}`, data);
  },
  remove(id) {
    return api().delete(`about-us/${id}`);
  },
};
