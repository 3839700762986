<template>
  <div>
    <b-card>
      <ValidationObserver ref="aboutUs" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <b-col md="6">
              <textarea-form
                v-model="aboutUs.about_us.translations[0].heading"
                :name="$t('global.headingAr')"
                :label="$t('global.headingAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <textarea-form
                v-model="aboutUs.about_us.translations[1].heading"
                :name="$t('global.headingEn')"
                :label="$t('global.headingEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <textarea-form
                v-model="aboutUs.about_us.translations[0].content"
                :name="$t('global.contentAr')"
                :label="$t('global.contentAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <textarea-form
                v-model="aboutUs.about_us.translations[1].content"
                :name="$t('global.contentEn')"
                :label="$t('global.contentEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
          </b-row>

          <b-col md="6">
            <input-form
              v-model="aboutUs.about_us.url"
              :name="$t('global.url')"
              :label="$t('global.url')"
              validate="required"
              :disabled="disableStatus"
            />
          </b-col>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import aboutUsServices from "../services/aboutUs";
import AboutUs from "../models/AboutUs";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";

export default {
  mixins: [moduleItemMixin],
  data() {
    return {
      aboutUs: new AboutUs(),
    };
  },
  methods: {
    async create() {
      aboutUsServices
        .create({ translations: this.aboutUs.about_us.translations })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "generalSettings.aboutUs" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update() {
      aboutUsServices
        .update(this.id, { translations: this.aboutUs.about_us.translations })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "generalSettings.aboutUs" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    if (this.id) {
      aboutUsServices.findOne(this.id).then((response) => {
        this.aboutUs.fillData(response.data.data);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
};
</script>
